export const zeroFill = (input, length) => {
  const inputStr = String(input)

  if (inputStr.trim().length >= length) {
    return inputStr
  } else {
    return inputStr.padStart(length, '0')
  }
}

zeroFill.description = `Pads the start of the provided input value with zeros to until it reaches the provided target length`

export default zeroFill
