import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import Avatar from '@material-ui/core/Avatar'

type StyledAvatarProps = PropsWithChildren<{
  className?: string
  color?: string
  hasAvatar?: boolean
  hasAcronym?: boolean
}>

const BaseAvatar = React.forwardRef(
  ({ hasAvatar, hasAcronym, color, ...props }: StyledAvatarProps, ref) => (
    <Avatar {...props} ref={ref as any} />
  ),
)

const StyledAvatar = styled(BaseAvatar)<StyledAvatarProps>`
  background-color: ${({ color, theme }) => color || theme.palette.text.secondary};
  font-size: ${({ hasAvatar, hasAcronym }) => !hasAvatar && hasAcronym && 18};
  color: #fff;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`

export interface AvatarIconProps {
  className?: string
  acronym?: string
  avatarIcon?: React.ReactNode
  color: string
}

const AvatarIcon = ({ acronym, avatarIcon, className, color }: AvatarIconProps) => {
  return (
    <StyledAvatar
      className={className}
      color={color}
      hasAcronym={!!acronym}
      hasAvatar={!!avatarIcon}
    >
      {avatarIcon || acronym}
    </StyledAvatar>
  )
}

export default AvatarIcon
