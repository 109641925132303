export default ({ breakpoints, spacing }) => ({
  addButton: {
    bottom: spacing(5),
    position: 'fixed',
    right: spacing(5),
  },
  searchInput: {
    marginBottom: spacing(2),
    marginTop: spacing(2),
    [breakpoints.down('md')]: {
      marginLeft: spacing(2),
      marginRight: spacing(2),
    },
  },
})
