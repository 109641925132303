import React from 'react'
import PropTypes from 'prop-types'
import { CirclePicker } from 'react-color'
import { storage } from 'services/firebase'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import IconToggle from 'components/Forms/IconToggle'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { DatePicker } from '@material-ui/pickers'
import Image from 'material-ui-image'
import SignatureBox from 'components/Forms/SignatureBox'

import camelToTitle from 'helpers/transforms/camel-to-title'

class FormField extends React.Component {
  static defaultProps = {
    disabled: false,
    value: '',
    readOnly: false,
  }

  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    field: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    index: PropTypes.number,
    initialValue: PropTypes.any,
    value: PropTypes.any.isRequired,
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    readOnly: PropTypes.bool.isRequired,
  }

  state = {
    showPassword: false,
    signatureUrl: null,
  }

  componentWillMount() {
    const { attendeeId, courseId, initialValue, type } = this.props
    if (type === 'signature' && initialValue) {
      // Value is URL to a storage path, it will be invalid by now so we need to get a new one
      storage
        .ref(`signatures/${courseId}/${attendeeId}.png`)
        .getDownloadURL()
        .then(signatureUrl => {
          this.setState({ signatureUrl })
        })
    }
  }

  render() {
    const {
      disabled,
      error,
      field,
      handleChange,
      index,
      initialValue,
      value,
      options,
      readOnly,
    } = this.props
    const { showPassword, signatureUrl } = this.state

    switch (field.type) {
      case 'color':
        return (
          <div>
            <Typography variant="caption" style={{ marginTop: 5, marginBottom: 5 }}>
              {field.label || camelToTitle(field.key)}
            </Typography>
            <CirclePicker
              color={value}
              onChangeComplete={({ hex }) => {
                handleChange(hex)
              }}
              width="100%"
            />
          </div>
        )

      case 'date':
        return (
          <DatePicker
            autoOk={true}
            format="dd/MM/yyyy"
            label={field.label || camelToTitle(field.key)}
            error={!!error}
            helperText={error}
            value={value}
            onChange={handleChange}
            margin="normal"
            fullWidth
            disabled={readOnly || disabled}
            variant="inline"
          />
        )

      case 'boolean':
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={event => handleChange(event.target.checked)}
                  disabled={readOnly || disabled}
                  color="primary"
                />
              }
              label={field.label || camelToTitle(field.key)}
            />
          </FormGroup>
        )

      case 'password':
        return (
          <TextField
            autoFocus={index === 0}
            label={field.label || camelToTitle(field.key)}
            error={!!error}
            helperText={error}
            id={field.key}
            type={showPassword ? 'text' : 'password'}
            value={value}
            onChange={event => handleChange(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => {
                      this.setState({ showPassword: !showPassword })
                    }}
                    onMouseDown={e => e.preventDefault()}
                  >
                    <IconToggle
                      on={showPassword}
                      onIcon={<Icon>visibility</Icon>}
                      offIcon={<Icon>visibility_off</Icon>}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            margin="normal"
            fullWidth
            disabled={readOnly || disabled}
          />
        )

      case 'select':
        return (
          <TextField
            autoFocus={index === 0}
            label={field.label || camelToTitle(field.key)}
            error={!!error}
            helperText={error}
            id={field.key}
            select
            value={value}
            onChange={event => handleChange(event.target.value)}
            margin="normal"
            fullWidth
            disabled={readOnly || disabled}
          >
            {Object.entries(options)
              .sort((a, b) => (a[1][field.options.name] > b[1][field.options.name] ? 1 : -1))
              .map(([id, option]) => (
                <MenuItem key={id} value={id}>
                  {option[field.options.name]}
                </MenuItem>
              ))}
          </TextField>
        )

      case 'signature':
        return initialValue ? (
          <div>
            <Typography variant="caption">Signature</Typography>
            <Image
              alt="Attendee signature"
              aspectRatio={552 / 150}
              imageStyle={{ height: '150px', width: '100%' }}
              src={signatureUrl || initialValue}
            />
          </div>
        ) : (
          <SignatureBox
            error={!!error}
            helperText={error}
            onChange={handleChange}
            width={window.screen.width < 600 ? window.screen.width : false}
          />
        )

      default:
        return (
          <TextField
            autoFocus={index === 0}
            label={field.label || camelToTitle(field.key)}
            error={!!error}
            helperText={error}
            id={field.key}
            type="text"
            value={value}
            onChange={event => handleChange(event.target.value)}
            margin="normal"
            fullWidth
            multiline={field.multiline}
            rows={field.multiline ? field.rows || 4 : null}
            disabled={readOnly || disabled}
          />
        )
    }
  }
}

export default FormField
