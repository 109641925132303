import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import ProviderList from './Main'

export default compose(
  firebaseConnect(() => {
    return ['courses', 'providers']
  }),
  connect(state => ({
    courses: state.firebase.data.courses || {},
    providers: state.firebase.data.providers || {},
  })),
)(ProviderList)
