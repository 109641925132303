import React from 'react'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import SvgIcon from '@material-ui/core/SvgIcon'

import { useStyles } from './styles'

const ColorDot = ({ color }) => (
  <SvgIcon style={{ fill: color }}>
    <path d="M23.563 11.96c.285 7.5-7.907 13.528-14.978 11.049C1.343 21.063-2.006 11.458 2.459 5.43c3.999-6.348 14.161-6.708 18.6-.659a11.583 11.583 0 0 1 2.504 7.19z" />
  </SvgIcon>
)

const ColorLegend = props => {
  const classes = useStyles()
  const { items, title } = props

  return (
    <div className={classes.root}>
      {title && (
        <div className={classes.title}>
          <Typography variant="h6">{title}</Typography>
        </div>
      )}
      <List dense>
        {items.map(item => (
          <ListItem key={item.name} className={classes.listItem}>
            <ColorDot color={item.color} />
            <Typography variant="body2" className={classes.itemTitle}>
              {item.name}
            </Typography>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

ColorLegend.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default ColorLegend
