export const styles = theme => ({
  root: {
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconButtonClose: {
    transition: 'transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  iconButtonSearch: {
    transition: 'transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    marginRight: -48,
  },
  icon: {
    transition: 'opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  input: {
    width: '100%',
  },
  searchContainer: {
    margin: 'auto 16px',
    width: '100%',
  },
})

export default styles
