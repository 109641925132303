import debugOnlyCall from 'helpers/conditional/debug-only-call'

const keysToProxy = [
  'assert',
  'clear',
  'count',
  'dir',
  'group',
  'groupCollapsed',
  'groupEnd',
  'log',
  'table',
  'time',
  'timeEnd',
  'trace',
  'warn',
]

export const initDevConsole = () => {
  keysToProxy.forEach(key => {
    const defaultAction = console[key]
    console[key] = new Proxy(defaultAction, debugOnlyCall)
  })
}

if (process.env.NODE_ENV !== 'development') {
  initDevConsole()
}
