import cache from 'services/localforage'
import { auth, database, messaging } from 'services/firebase'

export const initMessaging = callback => {
  const updateServerToken = newToken => {
    const update = {
      [newToken]: new Date(),
    }
    cache.getItem('fcmToken').then(oldToken => {
      if (oldToken !== newToken) {
        update[oldToken] = null
      }
      database
        .ref(`users/${auth.currentUser.uid}/fcmTokens`)
        .update(update)
        .then(() => cache.setItem('fcmToken', newToken))
    })
  }

  messaging
    .requestPermission()
    .then(() => {
      console.log('Permission granted')
      return messaging.getToken()
    })
    .then(updateServerToken)
    .then(() => {
      messaging.onTokenRefresh(() => {
        messaging.getToken().then(updateServerToken)
      })
    })
    .catch(err => {
      console.log('Error occurred: ', err)
    })

  messaging.onMessage(callback)
}
