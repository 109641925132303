import React from 'react'

import { withStyles, WithStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import FormField from 'components/Forms/FormField'
import ErrorBoundary from 'components/Utilities/ErrorBoundary'

import { confirmDelete as confirmDeleteText } from 'constants/messages'
import { styles } from './styles'
import { AnyField, SplitField } from './types'

export interface EditDialogProps extends WithStyles<typeof styles> {
  fields: (AnyField | SplitField)[]
  cancel: () => void
  update?: (value: Record<string, any>) => void
  remove?: () => void
  loading?: boolean
  submitLabel?: string
  title?: string
  noTitle?: boolean
  card?: boolean
  current?: Record<string, any>
  itemLabel?: string
  fullScreen?: boolean
  open?: boolean
  readOnly?: boolean
  errorMessage?: string
  [otherProp: string]: any
}

interface EditDialogState {
  confirmDelete: boolean
  data: Record<string, any>
  initialData: Record<string, any>
  invalid: Record<string, any>
}

class EditDialog extends React.Component<EditDialogProps, EditDialogState> {
  static defaultProps = {
    itemLabel: 'record',
    fullScreen: false,
    open: true,
  }

  constructor(props) {
    super(props)
    const { fields, current = {} } = this.props

    const data = fields.reduce(
      (state, field) =>
        field.type === 'split'
          ? {
              ...state,
              [field.left.key]: current[field.left.key] || field.left.default || '',
              [field.right.key]: current[field.right.key] || field.right.default || '',
            }
          : {
              ...state,
              [field.key]: current[field.key] || field.default || '',
            },
      {},
    )

    this.state = {
      confirmDelete: false,
      data,
      initialData: JSON.parse(JSON.stringify(data)),
      invalid: {},
    }
  }

  handleClickDelete = () => {
    this.setState({ confirmDelete: true })
  }

  handleCancelDelete = () => {
    this.setState({ confirmDelete: false })
  }

  handleClose = () => {
    this.props.cancel()
  }

  handleChange = (key: string) => (value: any) => {
    if (!this.props.readOnly) {
      this.setState({
        data: {
          ...this.state.data,
          [key]: value,
        },
        invalid: {
          ...this.state.invalid,
          [key]: false,
        },
      })
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    const { data } = this.state
    let formValid = true
    const invalid = {}

    const checkField = field => {
      if (field.validation && !field.validation(data[field.key])) {
        formValid = false
        invalid[field.key] = true
      }
    }

    this.props.fields.forEach(field => {
      if (field.type === 'split') {
        checkField(field.left)
        checkField(field.right)
      } else {
        checkField(field)
      }
    })

    if (formValid) {
      this.props.update(data)
    } else {
      this.setState({ invalid })
    }
  }

  render() {
    const {
      cancel,
      card,
      classes,
      errorMessage,
      fields,
      fullScreen,
      itemLabel,
      loading,
      noTitle,
      open,
      remove,
      submitLabel,
      title,
      update,
      ...otherProps
    } = this.props
    const { confirmDelete, data, initialData, invalid } = this.state

    const createFormField = (field: AnyField, index?: number) => (
      <FormField
        key={index}
        field={field}
        error={invalid[field.key] && field.error}
        index={index}
        value={data[field.key]}
        initialValue={initialData[field.key]}
        handleChange={this.handleChange(field.key)}
        options={field.type === 'select' && this.props[field.options.key]}
        disabled={typeof field.disabled === 'function' ? field.disabled(this.props.current) : false}
        readOnly={field.readOnly}
        {...otherProps}
      />
    )

    const formContent = (
      <form onSubmit={this.handleSubmit}>
        {fields.map((field, index) =>
          field.type === 'split' ? (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12} sm={6}>
                {createFormField(field.left)}
              </Grid>
              <Grid item xs={12} sm={6}>
                {createFormField(field.right)}
              </Grid>
            </Grid>
          ) : (
            createFormField(field, index)
          ),
        )}
        <input type="submit" className={classes.hiddenSubmit} />
      </form>
    )

    const formActions = (
      <ErrorBoundary>
        {remove && (
          <Button onClick={this.handleClickDelete} style={{ color: '#ff0000' }}>
            Delete
          </Button>
        )}
        {cancel && <Button onClick={cancel}>Cancel</Button>}
        {update && <Button onClick={this.handleSubmit}>{submitLabel || 'Save'}</Button>}
      </ErrorBoundary>
    )

    let deleteActions = null
    if (remove) {
      deleteActions = (
        <ErrorBoundary>
          <Button onClick={this.handleCancelDelete}>Cancel</Button>
          <Button onClick={remove} style={{ color: '#ff0000' }}>
            Delete
          </Button>
        </ErrorBoundary>
      )
    }

    return (
      <ErrorBoundary>
        {card ? (
          <Card className={classes.card}>
            {loading ? (
              <div className={classes.loadingWrapper}>
                <CircularProgress size={60} />
              </div>
            ) : confirmDelete ? (
              <React.Fragment>
                <CardContent>
                  <Typography variant="h5">Confirm Delete</Typography>
                  {confirmDeleteText(itemLabel)}
                  <DialogActions>{formActions}</DialogActions>
                </CardContent>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <CardContent>
                  {noTitle || <Typography variant="h5">{title || 'Edit Item'}</Typography>}

                  {errorMessage && (
                    <Typography className={classes.errorMessage}>{errorMessage}</Typography>
                  )}
                  {formContent}
                </CardContent>
                <CardActions className={classes.actions}>{formActions}</CardActions>
              </React.Fragment>
            )}
          </Card>
        ) : (
          <Dialog
            open={open}
            fullScreen={fullScreen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            {confirmDelete ? (
              <React.Fragment>
                <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent className={classes.dialog}>
                  <DialogContentText>{confirmDeleteText(itemLabel)}</DialogContentText>
                </DialogContent>
                <DialogActions>{deleteActions}</DialogActions>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {noTitle || (
                  <DialogTitle id="form-dialog-title">{title || 'Edit Item'}</DialogTitle>
                )}
                <DialogContent className={classes.dialog}>
                  {errorMessage && (
                    <Typography className={classes.errorMessage}>{errorMessage}</Typography>
                  )}
                  {formContent}
                </DialogContent>
                <DialogActions>{formActions}</DialogActions>
              </React.Fragment>
            )}
          </Dialog>
        )}
      </ErrorBoundary>
    )
  }
}

export default withStyles(styles)(withMobileDialog()(EditDialog))
