const filterByString = (searchString: string, otherFilter: (...args: any[]) => any) => ([
  id,
  course,
]) => {
  const fields = [
    course.title,
    course.company || '',
    course.cancelled ? 'cancelled' : '',
    ...(course.consultation ? ['consult', 'consultancy', 'consultation'] : []),
    Object.values(course.attendees || {})
      .map((attendee: any) => attendee.firstName + attendee.lastName + attendee.company)
      .join(' '),
  ]
  return (
    fields.reduce(
      (match, value) =>
        match
          ? match
          : String(value)
              .toLowerCase()
              .includes(searchString.toLowerCase()),
      false,
    ) && otherFilter(course)
  )
}

export default filterByString
