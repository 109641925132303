import React from 'react'
import SignaturePad from 'signature_pad/dist/signature_pad'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import styles from 'styles/components/SignatureBox'
import red from '@material-ui/core/colors/red'

class SignatureBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      height: window.outerHeight >= 750 ? 150 : 100,
      width:
        window.outerWidth < 600
          ? window.outerWidth - props.theme.spacing(6)
          : 600 - props.theme.spacing(6),
    }

    this.signatureCanvas = React.createRef()
  }

  componentDidMount() {
    this.signaturePad = new SignaturePad(this.signatureCanvas.current, {
      onEnd: () => {
        this.props.onChange(this.signaturePad.toDataURL())
      },
    })

    window.addEventListener('resize', this.resizeCanvas)
  }

  componentWillUnmount() {
    this.signaturePad.off()
    window.removeEventListener('resize', this.resizeCanvas)
  }

  handleClear = () => {
    this.signaturePad.clear()
    this.props.onChange('')
  }

  resizeCanvas = () => {
    this.setState({
      height: window.outerHeight >= 750 ? 150 : 100,
      width:
        window.outerWidth < 600
          ? window.outerWidth - this.props.theme.spacing(6)
          : 600 - this.props.theme.spacing(6),
    })
    this.signaturePad.clear()
    this.props.onChange('')
  }

  render() {
    const { classes, error, helperText } = this.props

    return (
      <div style={{ textAlign: 'left' }}>
        <Typography
          className={classes.label}
          variant="caption"
          style={{ color: error && red[500] }}
        >
          Signature
        </Typography>
        <canvas
          width={this.state.width}
          height={this.state.height}
          className={classes.signatureCanvas}
          style={{ borderColor: error && red[500] }}
          ref={this.signatureCanvas}
        />
        {error && (
          <Typography variant="caption" style={{ color: red[500] }}>
            {helperText}
          </Typography>
        )}
        <div>
          <Button size="small" color="primary" onClick={this.handleClear}>
            Clear
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(SignatureBox)
