import { DRAWER_WIDTH } from 'constants/layout'

export const styles = theme => ({
  appBar: {
    position: 'fixed',
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up('md')]: { width: `calc(100% - ${DRAWER_WIDTH}px)` },
  },
  headerSpreader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  navIconHide: { [theme.breakpoints.up('md')]: { display: 'none' } },
  userBlock: {
    display: 'flex',
    alignItems: 'center',
    spaceBetween: 10,
  },
})

export default styles
