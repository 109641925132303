import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import Fab from '@material-ui/core/Fab'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Icon from '@material-ui/core/Icon'
import withStyles from '@material-ui/core/styles/withStyles'

import EditDialog from 'components/Dialogs/EditDialog/EditDialog'
import PageLayout from 'components/Layout/PageLayout'
import SearchBar from 'components/Cards/SearchBar'
import { providerSchema } from 'constants/schemas'
import getAcronym from 'helpers/transforms/get-acronym'
import { database } from 'services/firebase'
import filterByString from './helpers/filter-by-string'
import styles from './styles'
import { Typography } from '@material-ui/core'

class ProviderList extends React.Component {
  state = {
    provider: null,
    searchString: '',
  }

  canDeleteProvider = providerID =>
    !Object.values(this.props.courses).some(course => course.provider === providerID)

  handleChange = key => value => {
    this.setState({ [key]: value })
  }

  handleProviderAdd = () => {
    this.setState({
      provider: database.ref('providers').push().key,
    })
  }

  handleProviderDelete = id => () => {
    database
      .ref('providers')
      .child(id)
      .remove()
      .then(
        this.setState({
          provider: undefined,
        }),
      )
  }

  handleProviderSelect = id => () => {
    this.setState({ provider: id })
  }

  handleProviderUpdate = id => provider => {
    database
      .ref('providers')
      .child(id)
      .set(provider)

    this.setState({
      provider: null,
    })
  }

  handleCancel = () => {
    this.setState({ provider: null })
  }

  render() {
    const { provider, searchString } = this.state
    const { avatarIcon, classes, providers } = this.props

    return (
      <PageLayout noCard title="Providers" slim={true}>
        {provider && (
          <EditDialog
            title="Provider Details"
            cancel={this.handleCancel}
            current={providers[provider]}
            providers={providers}
            fields={providerSchema}
            update={this.handleProviderUpdate(provider)}
            itemLabel="provider"
            remove={this.canDeleteProvider(provider) && this.handleProviderDelete(provider)}
          />
        )}
        <div className={classes.searchInput}>
          <SearchBar onChange={this.handleChange('searchString')} value={searchString} />
        </div>
        {Object.keys(providers).length ? (
          <List>
            {Object.entries(providers)
              .filter(filterByString(searchString))
              .sort((a, b) => (a[1].name > b[1].name ? 1 : -1))
              .map(([id, { color, name }]) => {
                const acronym = !avatarIcon && getAcronym(name)
                return (
                  <ListItem
                    button={true}
                    divider={true}
                    key={id}
                    onClick={this.handleProviderSelect(id)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          backgroundColor: color,
                          color: '#fff',
                          fontSize: !avatarIcon && acronym && 18,
                        }}
                      >
                        {avatarIcon || acronym}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={name} />
                  </ListItem>
                )
              })}
          </List>
        ) : (
          <Typography subtitle1 align="center" style={{ paddingTop: 16 }}>
            No providers yet, try adding one!
          </Typography>
        )}
        <Fab
          color="secondary"
          aria-label="add"
          className={classes.addButton}
          onClick={this.handleProviderAdd}
        >
          <Icon>add</Icon>
        </Fab>
      </PageLayout>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ProviderList)
