import { green } from '@material-ui/core/colors'

export default ({ breakpoints, palette, spacing }) => ({
  appTitle: {
    color: palette.primary.contrastText,
    cursor: 'pointer',
    fontFamily: "'Monoton', cursive",
    fontSize: 42,
  },
  appTitleWrapper: {
    [breakpoints.down('xs')]: {
      position: 'relative',
    },
    left: 0,
    paddingLeft: spacing(2),
    paddingTop: spacing(1),
    position: 'fixed',
    top: 0,
    width: '100%',
  },
  card: {
    minWidth: 350,
  },
  submit: {
    display: 'none',
  },
  title: {
    color: palette.primary.contrastText,
    marginBottom: spacing(2),
  },
  welcome: {
    color: palette.primary.contrastText,
    marginBottom: spacing(1),
    marginTop: spacing(1),
    maxWidth: 600,
  },
  welcomeIcon: {
    backgroundColor: green[500],
    borderRadius: '50%',
    color: '#fff',
    fontSize: 200,
  },
  wrapper: {
    alignItems: 'center',
    background: palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
    position: 'absolute',
    // tslint:disable-next-line:object-literal-sort-keys
    'overflow-x': 'hidden',
    'overflow-y': 'auto',
    width: '100%',
  },
})
