export const API_KEY = 'AIzaSyAfTj0LTDb-sk7YaFSLLAtJP0P-eWbHKts'
export const AUTH_DOMAIN = 'student-tracker-43fc4.firebaseapp.com'
export const DATABASE_URL = 'https://student-tracker-43fc4.firebaseio.com'
export const PROJECT_ID = 'student-tracker-43fc4'
export const STORAGE_BUCKET = 'student-tracker-43fc4.appspot.com'
export const MESSAGING_SENDER_ID = '397510232608'
export const WEB_PUSH_KEY =
  'BGRzWd2JivvoXNLUQ6yb1iRuOxIse9K8L4h2jtlWaVzilmIw0Y1PiJ_GLjwtmWiCV-mf_ek_4xopLLlz5hGCW84'

export const config = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: DATABASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
}

export default config
