import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase'
import { database, storage } from 'services/firebase'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import Pagination from 'material-ui-flat-pagination'

import EditDialog from 'components/Dialogs/EditDialog/EditDialog'
import PageLayout from 'components/Layout/PageLayout'
import ColorLegend from 'components/Collections/ColorLegend'
import SearchBar from 'components/Cards/SearchBar'
import CoursePanel from './CoursePanel'

import { bookingSchema, registrationSchema } from 'constants/schemas'
import filterByString from './helpers/filter-by-string'
import arrayToCsv from 'helpers/file/array-to-csv'
import dateString from 'helpers/generators/date-string'
import { useStyles } from './styles'
import { SplitField, AnyField } from 'components/Dialogs/EditDialog/types'
import useDebounce from 'hooks/useDebounce'

const PAGE_SIZE = 30

export interface CourseListProps {
  avatarIcon: any
  filter: any
  sort: any
  title: string
  user: any
  readOnly: boolean
}

const CourseList = (props: CourseListProps) => {
  const { avatarIcon, filter, sort, title, user, readOnly } = props

  const classes = useStyles({})

  const history = useHistory()

  useFirebaseConnect(['courses', 'providers'])
  const courses = useSelector((state: any) => state.firebase.data.courses || {})
  const providers = useSelector((state: any) => state.firebase.data.providers || {})

  const [attendee, setAttendee] = useState(null)
  const [course, setCourse] = useState(null)
  const [copiedCourse, setCopiedCourse] = useState(null)
  const [expanded, setExpanded] = useState()
  const [searchString, setSearchString] = useState('')
  const debouncedSearchString = useDebounce(searchString, 200)

  const filteredCourses = useMemo(() => {
    return Object.entries(courses)
      .filter(filterByString(debouncedSearchString, filter))
      .sort(sort)
  }, [courses, filter, debouncedSearchString, sort])

  const [offset, setOffset] = useState(0)

  useEffect(() => {
    if (filteredCourses.length < offset) {
      const newPage = Math.floor(filteredCourses.length / PAGE_SIZE)
      setOffset(newPage * PAGE_SIZE)
    }
  }, [filteredCourses, offset])

  const visibleCourses = useMemo(() => {
    return filteredCourses.slice(offset, offset + PAGE_SIZE)
  }, [filteredCourses, offset])

  const handleCourseCopy = useCallback(
    course => () => {
      setCopiedCourse({
        ...course,
        attendees: undefined,
        archived: false,
        cancelled: false,
      })
      setCourse(database.ref('courses').push().key)
    },
    [],
  )

  const handleCourseAdd = useCallback(() => {
    setCourse(database.ref('courses').push().key)
  }, [])

  const handleCourseArchive = useCallback(
    (courseId, archive) => () => {
      database
        .ref('courses')
        .child(courseId)
        .update({ archive })
    },
    [],
  )

  const handleCourseSelect = useCallback(
    id => () => {
      setCourse(id)
    },
    [],
  )

  const handleCourseUpdate = useCallback(
    id => course => {
      database
        .ref('courses')
        .child(id)
        .update(course)

      setCourse(null)
      setCopiedCourse(null)

      if (readOnly) {
        // We are on the course archive screen and a course is being copied,
        // redirect to the main courses page to see the new course
        history.push('/courses')
      }
    },
    [history, readOnly],
  )

  const handleCancel = useCallback(() => {
    setAttendee(null)
    setCourse(null)
    setCopiedCourse(null)
  }, [setAttendee, setCourse, setCopiedCourse])

  const deleteAttendeeSignature = useCallback((courseId, attendeeId) => {
    storage.ref(`signatures/${courseId}/${attendeeId}.png`).delete()
  }, [])

  const handleCourseDelete = useCallback(
    courseId => () => {
      const attendees = courses[courseId].attendees || []
      attendees.forEach(attendeeId => deleteAttendeeSignature(courseId, attendeeId))

      database
        .ref('courses')
        .child(courseId)
        .remove()

      handleCancel()
    },
    [courses, deleteAttendeeSignature, handleCancel],
  )

  const handleExpand = useCallback(
    key => (event, expanded) => {
      setExpanded(expanded ? key : false)
    },
    [setExpanded],
  )

  const handleCourseAttendeeDelete = useCallback(
    (courseId, attendeeId) => () => {
      deleteAttendeeSignature(courseId, attendeeId)

      database
        .ref(`courses/${courseId}/attendees`)
        .child(attendeeId)
        .remove()

      handleCancel()
    },
    [deleteAttendeeSignature, handleCancel],
  )

  const handleCourseAttendeeSelect = useCallback(
    (courseId, attendeeId) => () => {
      setAttendee({ course: courseId, id: attendeeId })
    },
    [],
  )

  const handleCourseAttendeeUpdate = useCallback(
    (courseId, attendeeId) => attendee => {
      database
        .ref(`courses/${courseId}/attendees`)
        .child(attendeeId)
        .update(attendee)

      handleCancel()
    },
    [handleCancel],
  )

  const handleCourseAttendeeDownload = useCallback(
    courseId => () => {
      const course = courses[courseId]
      arrayToCsv(
        Object.values(course.attendees).map(({ signature, ...attendee }) => attendee),
        `${dateString()} - ${course.title}`,
      )
    },
    [courses],
  )

  const handleEnterRegistrationMode = useCallback(
    courseId => () => {
      database.ref(`users/${user.uid}`).update({
        registrationMode: courseId,
      })
    },
    [user.uid],
  )

  if (!isLoaded(courses) || !isLoaded(providers)) {
    return <Typography>Loading</Typography>
  }

  return (
    <PageLayout
      noCard
      title={title}
      rightGutter={
        Object.keys(providers).length > 0 && (
          <ColorLegend items={Object.values(providers)} title="Provider Key" />
        )
      }
      rightGutterFixed
    >
      {(course || attendee) && (
        <EditDialog
          title={course ? 'Course Details' : 'Attendee Details'}
          cancel={handleCancel}
          current={
            course
              ? copiedCourse || courses[course]
              : courses[attendee.course].attendees[attendee.id]
          }
          itemLabel={course ? 'course' : 'attendee'}
          providers={providers}
          fields={(course ? bookingSchema : registrationSchema) as (AnyField | SplitField)[]}
          update={
            (copiedCourse || !readOnly) &&
            (course
              ? handleCourseUpdate(course)
              : handleCourseAttendeeUpdate(attendee.course, attendee.id))
          }
          remove={
            !readOnly &&
            (course
              ? handleCourseDelete(course)
              : handleCourseAttendeeDelete(attendee.course, attendee.id))
          }
          attendeeId={attendee && attendee.id}
          courseId={attendee && attendee.course}
          readOnly={readOnly && !copiedCourse}
        />
      )}
      {/* Text search panel */}
      <div className={classes.searchInput}>
        <SearchBar onChange={setSearchString} value={searchString} />
      </div>
      {Object.keys(courses).length ? (
        <div>
          {visibleCourses.map(([courseId, course]) => (
            <CoursePanel
              avatarIcon={avatarIcon}
              course={course}
              courseId={courseId}
              expanded={expanded === courseId}
              handleCourseArchive={handleCourseArchive}
              handleCourseAttendeeDownload={handleCourseAttendeeDownload}
              handleCourseAttendeeSelect={handleCourseAttendeeSelect}
              handleCourseCopy={handleCourseCopy}
              handleCourseSelect={handleCourseSelect}
              handleEnterRegistrationMode={handleEnterRegistrationMode}
              handleExpand={handleExpand}
              provider={providers[(course as any).provider]}
              key={courseId}
              readOnly={readOnly}
            />
          ))}

          <Box width="100%" textAlign="center" mt={2}>
            <Pagination
              limit={PAGE_SIZE}
              offset={offset}
              total={filteredCourses.length}
              onClick={(e, offset) => setOffset(offset)}
            />
          </Box>
        </div>
      ) : (
        <Typography variant="subtitle1" align="center" style={{ paddingTop: 16 }}>
          No courses yet,
          {!Object.keys(providers).length ? (
            <span>
              {' '}
              add a provider in the{' '}
              <a
                href="/providers"
                onClick={event => {
                  event.preventDefault()
                  history.push('/providers')
                }}
              >
                providers
              </a>{' '}
              page to get started
            </span>
          ) : (
            ' try adding one now!'
          )}
        </Typography>
      )}
      <Fab
        color="secondary"
        aria-label="add"
        className={classes.addButton}
        onClick={handleCourseAdd}
      >
        <Icon>add</Icon>
      </Fab>
    </PageLayout>
  )
}

export default CourseList
