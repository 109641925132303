import camelToTitle from 'helpers/transforms/camel-to-title'

export const arrayToCsvString = arrayData => {
  if (!arrayData.length) {
    return false
  }

  const headers = Object.keys(arrayData[0])
  const rows = arrayData.map(item =>
    headers
      .map(key => {
        let cell = String(item[key] || '').replace('"', '""')
        if (cell.includes(',')) {
          cell = `"${cell}"`
        }
        return cell
      })
      .join(','),
  )

  return [headers.map(key => `"${camelToTitle(key)}"`).join(','), ...rows].join('\r\n')
}

arrayToCsvString.description = `Transforms a provided array of objects with the same keys into a CSV string.`

export default arrayToCsvString
