import { blueGrey, lightBlue } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core'

export const themeData = {
  palette: {
    primary: {
      main: blueGrey[700],
      light: blueGrey[500],
      dark: blueGrey[900],
    },
    secondary: {
      main: lightBlue[600],
      light: lightBlue[400],
      dark: lightBlue[800],
    },
  },
}

export const theme = createMuiTheme(themeData)
