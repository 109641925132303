import { makeStyles } from '@material-ui/core/styles'

export const styles = {
  avatar: {
    height: 30,
    width: 30,
    fontSize: 15,
  },
  root: {
    textAlign: 'left',
  },
  title: {
    marginLeft: 16,
  },
  itemTitle: {
    marginLeft: 4,
  },
  listItem: {
    marginBottom: 8,
  },
}

export const useStyles = makeStyles(styles, { name: 'ColorLegend' })

export default styles
