import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import firebase from 'services/firebase'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import getRouteTitle from 'helpers/nav/get-route-title'
import firstString from 'helpers/conditional/first-string'
import styles from 'styles/components/Header'

const useStyles = makeStyles(styles, { name: 'Header' })

const Header = props => {
  const { editUser, toggle, user } = props

  const classes = useStyles()
  const [title, setTitle] = useState(() => getRouteTitle(props.location.pathname))
  const [anchorEl, setAnchorEl] = useState(null)

  const handleTitleChange = useCallback(
    location => {
      setTitle(getRouteTitle(location.pathname))
    },
    [setTitle],
  )

  useEffect(() => {
    return props.history.listen(handleTitleChange)
  }, [props.history, handleTitleChange])

  const handleUserMenuAction = action => () => {
    setAnchorEl(null)
    setTimeout(action, 50)
  }

  const handleOpenUserMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggle}
          className={classes.navIconHide}
        >
          <Icon>menu</Icon>
        </IconButton>

        <div className={classes.headerSpreader}>
          <Typography variant="h6" color="inherit" noWrap>
            {title}
          </Typography>
          <div className={classes.userBlock}>
            <Typography color="inherit">{firstString(user.displayName, user.email)}</Typography>
            <IconButton
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={handleOpenUserMenu}
              color="inherit"
            >
              <Icon>account_circle</Icon>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleUserMenuAction(editUser)}>Profile</MenuItem>
              <MenuItem
                onClick={handleUserMenuAction(() => {
                  firebase.auth().signOut()
                })}
              >
                Sign Out
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}
export default withStyles(styles, { withTheme: true })(withRouter(Header))
