export const styles = theme => ({
  label: {
    marginTop: 20,
    marginBottom: 5,
  },
  signatureCanvas: {
    border: '1px solid ' + theme.palette.action.active,
    borderRadius: 2,
  },
})

export default styles
