import zeroFill from 'helpers/transforms/zero-fill'

export const timeString = () => {
  const d = new Date()
  return [zeroFill(d.getHours(), 2), zeroFill(d.getMinutes(), 2), zeroFill(d.getSeconds(), 2)].join(
    '',
  )
}

export default timeString
