import format from 'date-fns/format'
import formatDistance from 'date-fns/formatDistance'
import addHours from 'date-fns/addHours'

export default dateString => {
  const date = new Date(dateString)
  return `${format(date, 'E dd/MM/yyyy')} (${formatDistance(addHours(date, 9), new Date(), {
    addSuffix: true,
  })})`
}
