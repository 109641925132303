import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'

import Main from 'components/Frame/Main'
import { APP_TITLE } from 'constants/customization'
import { auth } from 'services/firebase'
import Login from 'views/Login'

class App extends React.Component {
  state = {
    authed: false,
    loading: true,
    user: null,
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth.onAuthStateChanged(user => {
      this.setState({
        authed: !!user,
        loading: false,
        user,
      })
    })
  }

  componentWillUnmount() {
    this.unregisterAuthObserver()
  }

  render() {
    const { loading, authed, user } = this.state

    return (
      <>
        <CssBaseline />
        {authed ? <Main user={user} /> : <Login loading={loading} title={APP_TITLE} />}
      </>
    )
  }
}

export default App
