import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import styles from './styles'
import getAcronym from 'helpers/transforms/get-acronym'
import formatDate from './helpers/format-date'
import AvatarIcon from 'components/Collections/AvatarIcon'

const CoursePanel = props => {
  const {
    avatarIcon,
    classes,
    course,
    courseId,
    expanded,
    handleCourseArchive,
    handleCourseAttendeeDownload,
    handleCourseAttendeeSelect,
    handleCourseCopy,
    handleCourseSelect,
    handleEnterRegistrationMode,
    handleExpand,
    provider,
    readOnly,
    theme,
  } = props
  const acronym = !avatarIcon && getAcronym(provider.name)

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleExpand(courseId)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
        <div className={classes.expandSummary}>
          <AvatarIcon
            avatarIcon={avatarIcon}
            acronym={acronym}
            color={course.cancelled ? theme.palette.text.secondary : provider.color}
          />
          <Typography
            className={classes.heading}
            style={{
              color: course.cancelled ? theme.palette.text.secondary : false,
              textDecoration: course.cancelled ? 'line-through' : false,
            }}
          >
            {course.title}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`${formatDate(new Date(course.date))}${course.company && ' - ' + course.company}`}
          </Typography>
        </div>
      </ExpansionPanelSummary>

      <>
        <ExpansionPanelDetails>
          {course.consultation ? (
            <Typography>Consultation only</Typography>
          ) : course.attendees ? (
            <div>
              <Typography>Attendees ({Object.keys(course.attendees).length})</Typography>
              {Object.entries(course.attendees).map(([attendeeId, attendee]) => (
                <Chip
                  avatar={
                    <Avatar>{getAcronym(`${attendee.firstName} ${attendee.lastName}`)}</Avatar>
                  }
                  key={attendeeId}
                  className={classes.attendeeChip}
                  label={
                    attendee.firstName +
                    ' ' +
                    attendee.lastName +
                    (attendee.emailAddress.trim().length ? ' - ' + attendee.emailAddress : '')
                  }
                  onClick={handleCourseAttendeeSelect(courseId, attendeeId)}
                />
              ))}
            </div>
          ) : (
            <Typography>No attendees</Typography>
          )}
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          {expanded && (
            <>
              <Tooltip title="Copy booking" placement="right">
                <IconButton
                  onClick={handleCourseCopy(course)}
                  onMouseDown={event => event.preventDefault()}
                >
                  <Icon>content_copy</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip
                title={course.archive ? 'Restore booking' : 'Archive booking'}
                placement="right"
              >
                <IconButton
                  onClick={handleCourseArchive(courseId, !course.archive)}
                  onMouseDown={event => event.preventDefault()}
                >
                  <Icon>{course.archive ? 'unarchive' : 'archive'}</Icon>
                </IconButton>
              </Tooltip>
              {course.attendees && (
                <Tooltip title="Download attendee data" placement="right">
                  <IconButton
                    onClick={handleCourseAttendeeDownload(courseId)}
                    onMouseDown={event => event.preventDefault()}
                  >
                    <Icon>file_download</Icon>
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={readOnly ? 'View details' : 'Enter edit mode'} placement="right">
                <IconButton
                  onClick={handleCourseSelect(courseId)}
                  onMouseDown={event => event.preventDefault()}
                >
                  <Icon>{readOnly ? 'visibility' : 'edit'}</Icon>
                </IconButton>
              </Tooltip>
              {!readOnly && !course.consultation && (
                <Tooltip title="Enter registration mode" placement="right">
                  <IconButton
                    onClick={handleEnterRegistrationMode(courseId)}
                    onMouseDown={event => event.preventDefault()}
                  >
                    <Icon>fingerprint</Icon>
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </ExpansionPanelActions>
      </>
    </ExpansionPanel>
  )
}

export default withStyles(styles, { withTheme: true })(CoursePanel)
