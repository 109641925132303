import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import withMobileDialog from '@material-ui/core/withMobileDialog'

import styles from 'styles/components/PageLayout'

class PageLayout extends React.Component {
  render() {
    const {
      children,
      classes,
      fullScreen,
      wide,
      noCard,
      title,
      rightGutter,
      rightGutterFixed,
      slim,
    } = this.props

    const pageContent = (
      <div ref="mainPanel">
        {title && (
          <div className={classes.title}>
            <Typography variant="h2">{title}</Typography>
          </div>
        )}
        {children}
      </div>
    )

    return (
      <div className={classes.root}>
        <Grid container>
          <Hidden smDown>
            <Grid item md={!wide && 1} lg={slim ? 2 : null} />
          </Hidden>
          <Grid item xs={12} md={wide ? 12 : 10} lg={rightGutter || slim ? 8 : wide ? 12 : 10}>
            {fullScreen || noCard ? (
              pageContent
            ) : (
              <Paper className={classes.paper}>{pageContent}</Paper>
            )}
          </Grid>
          <Hidden smDown>
            <Grid item md={!wide && 1} lg={rightGutter ? 3 : slim ? 2 : wide ? false : 1}>
              {rightGutter && (
                <Hidden mdDown>
                  <div className={rightGutterFixed ? classes.rightGutterFixed : null}>
                    {rightGutter}
                  </div>
                </Hidden>
              )}
            </Grid>
          </Hidden>
        </Grid>
        {rightGutter && (
          <Hidden lgUp>
            <Grid container>
              <Hidden smDown>
                <Grid item md />
              </Hidden>
              <Grid item xs={12} md={10}>
                <div className={classes.rightGutterWrapped}>{rightGutter}</div>
              </Grid>
              <Hidden smDown>
                <Grid item md />
              </Hidden>
            </Grid>
          </Hidden>
        )}
      </div>
    )
  }
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  rightGutter: PropTypes.node,
  rightGutterFixed: PropTypes.bool,
}

export default withStyles(styles)(withMobileDialog()(PageLayout))
