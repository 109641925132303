import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { DRAWER_WIDTH } from 'constants/layout'

export const styles = (theme: Theme) =>
  createStyles({
    activeListItem: {
      background: theme.palette.secondary.main,
    },
    activeListIcon: {
      color: theme.palette.secondary.contrastText,
    },
    activeListText: {
      color: theme.palette.secondary.contrastText,
      fontWeight: 'bold',
    },
    drawerHeader: Object.assign({}, theme.mixins.toolbar, {
      display: 'grid',
      height: 56,
      [theme.breakpoints.up('sm')]: {
        height: 64,
      },
    }),
    drawerHeaderWrapper: {
      margin: 'auto',
      marginLeft: theme.spacing(2),
    },
    drawerTitle: {
      fontFamily: "'Monoton', cursive",
      fontSize: 36,
      lineHeight: 1,
      display: 'block',
    },
    drawerPaper: {
      borderRight: 0,
      boxShadow: theme.shadows[4],
      width: DRAWER_WIDTH,
      [theme.breakpoints.up('md')]: {
        width: DRAWER_WIDTH,
        position: 'fixed',
        height: '100%',
      },
    },
    versionLink: {
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      '&:hover': {
        color: theme.palette.text.secondary,
        textDecoration: 'underline',
        textDecorationColor: theme.palette.text.secondary,
      },
    },
  })

export const useStyles = makeStyles(styles, { name: 'SideNav' })

export default styles
