import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/messaging'
import 'firebase/storage'

import config from 'constants/firebase'

firebase.initializeApp(config)

if (process.env.NODE_ENV === 'development') {
  window.auth = firebase.auth()
  window.database = firebase.database()
  window.storage = firebase.storage()
}

export { firebase }
export const provider = new firebase.auth.EmailAuthProvider()
export const auth = firebase.auth()
export const database = firebase.database()
export const messaging = firebase.messaging()
export const storage = firebase.storage()
export default firebase
