import React from 'react'
import { database, storage } from 'services/firebase'

import Grow from '@material-ui/core/Grow'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import EditDialog from 'components/Dialogs/EditDialog/EditDialog'
import ParticleBackground from 'components/Layout/ParticleBackground'
import ErrorBoundary from 'components/Utilities/ErrorBoundary'

import { APP_TITLE } from 'constants/customization'
import { pinUnlockSchema, registrationSchema } from 'constants/schemas'
import styles from './styles'

class Registration extends React.Component {
  state = {
    pinCheck: false,
    pinFail: false,
    welcome: false,
    welcomeTransition: false,
  }

  handlePinCheck = () => {
    this.setState({ pinCheck: true })
  }

  handleCancelPinCheck = () => {
    this.setState({ pinCheck: false })
  }

  handleClosePinFail = () => {
    this.setState({ pinFail: false })
  }

  handleUnlock = ({ pin }) => {
    if (String(this.props.userState.pin || '1234') === String(pin)) {
      database
        .ref('users')
        .child(this.props.user.uid)
        .update({ registrationMode: '' })
    } else {
      this.setState({ pinCheck: false, pinFail: true })
    }
  }

  handleRegistration = attendee => {
    const attendeeRef = database
      .ref(`courses/${this.props.userState.registrationMode}`)
      .child('attendees')
      .push()

    storage
      .ref()
      .child(`signatures/${this.props.userState.registrationMode}/${attendeeRef.key}.png`)
      .putString(attendee.signature.split(',')[1], 'base64')
      .then(async snapshot => {
        attendeeRef.set({
          ...attendee,
          signature: await snapshot.ref.getDownloadURL(),
        })
      })
      .catch(console.error)

    this.setState(
      {
        welcome: attendee.firstName,
        welcomeTransition: true,
      },
      () => {
        this.timeout = setTimeout(() => {
          this.setState(
            {
              welcomeTransition: false,
            },
            () => {
              this.timeout = setTimeout(() => {
                this.setState({ welcome: false })
              }, 500)
            },
          )
        }, 3500)
      },
    )
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { classes, userState, courses } = this.props
    const { pinCheck, pinFail, welcome, welcomeTransition } = this.state

    const course = courses[userState.registrationMode]

    return (
      <ErrorBoundary>
        <ParticleBackground>
          <div className={classes.wrapper}>
            <div className={classes.appTitleWrapper}>
              <Typography variant="h5" className={classes.appTitle} onClick={this.handlePinCheck}>
                {APP_TITLE}
              </Typography>
            </div>
            {pinCheck && (
              <EditDialog
                cancel={this.handleCancelPinCheck}
                fields={pinUnlockSchema}
                open={true}
                title="Exit Registration Mode"
                submitLabel="Unlock"
                update={this.handleUnlock}
              />
            )}
            <Snackbar
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              open={pinFail}
              autoHideDuration={5000}
              onClose={this.handleClosePinFail}
              message={<span id="incorrect-pin">Incorrect PIN</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.handleClosePinFail}
                >
                  <Icon>close</Icon>
                </IconButton>,
              ]}
            />
            <div
              style={{
                display: pinCheck ? 'none' : undefined,
                textAlign: 'center',
              }}
            >
              {welcome ? (
                <Grow in={welcomeTransition}>
                  <div>
                    <Icon className={classes.welcomeIcon}>done</Icon>
                    <Typography variant="h5" className={classes.welcome}>
                      Welcome {welcome}!
                    </Typography>
                  </div>
                </Grow>
              ) : (
                <Grow in={!welcome}>
                  <div>
                    <Typography variant="h5" className={classes.welcome}>
                      Welcome to {course.title || 'Course Title'}!
                    </Typography>
                    <EditDialog
                      card={true}
                      current={{
                        company: course.company || '',
                      }}
                      fields={registrationSchema}
                      title="Sign In"
                      submitLabel="Sign In"
                      update={this.handleRegistration}
                    />
                  </div>
                </Grow>
              )}
            </div>
          </div>
        </ParticleBackground>
      </ErrorBoundary>
    )
  }
}

export default withStyles(styles)(Registration)
