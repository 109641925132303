import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import ParticleBackground from 'components/Layout/ParticleBackground'

import styles from 'styles/components/LoadingSplash'

const LoadingSplash = props => {
  const { classes } = props

  return (
    <ParticleBackground>
      <Typography variant="h5" className={classes.title}>
        Loading
      </Typography>
      <CircularProgress size={60} className={classes.spinner} />
    </ParticleBackground>
  )
}

export default withStyles(styles)(LoadingSplash)
