import dateTimeString from 'helpers/generators/date-time-string'
import arrayToCsvString from 'helpers/transforms/array-to-csv-string'

export const arrayToCsv = (arrayData, filename = '') => {
  if (!filename.trim().length) {
    filename = dateTimeString()
  }

  const uriContent =
    'data:text/plain;charset=utf-8,' + encodeURIComponent(arrayToCsvString(arrayData))

  const el = document.createElement('a')

  el.setAttribute('href', uriContent)
  el.setAttribute('download', `${filename}.csv`)
  el.style.display = 'none'

  document.body.appendChild(el)
  el.click()
  document.body.removeChild(el)
}

export default arrayToCsv
