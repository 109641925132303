import React from 'react'
import 'particles.js'
import { withStyles } from '@material-ui/core/styles'

import styles, { particleStyles } from 'styles/components/ParticleBackground'

class ParticleBackground extends React.Component {
  componentDidMount() {
    window.particlesJS('particle-wrapper', particleStyles)
  }

  render() {
    const { children, classes } = this.props

    return (
      <div id="particle-wrapper" className={classes.wrapper}>
        {children}
      </div>
    )
  }
}

export default withStyles(styles)(ParticleBackground)
