import createReducer from 'helpers/redux/createReducer'
import { START_LOADING, STOP_LOADING } from 'actions/ui'

const initialState = {
  loading: true,
}

export default createReducer(initialState, {
  [START_LOADING]: state => ({
    ...state,
    loading: true,
  }),
  [STOP_LOADING]: state => ({
    ...state,
    loading: false,
  }),
})
