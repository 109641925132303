const filterByString = searchString => ([, provider]) => {
  const fields = [provider.name]
  return fields.reduce(
    (match, value) =>
      match
        ? match
        : String(value)
            .toLowerCase()
            .includes(searchString.toLowerCase()),
    false,
  )
}

export default filterByString
