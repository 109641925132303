import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import clipPathSupported from 'helpers/support/clip-path-supported'

import styles from 'styles/components/IconToggle'

const clipPath = value => ({
  WebkitClipPath: value,
  clipPath: value,
})

const ToggleIcon = props => {
  const { classes, offIcon, onIcon, on, ...other } = props

  return (
    <div className={classes.root} {...other}>
      {React.cloneElement(offIcon, {
        className: classes.offIcon,
        style: {
          ...clipPath(on ? 'polygon(0% 0%, 0% 0%, 0% 0%)' : 'polygon(0% 200%, 0% 0%, 200% 0%)'),
          visibility: !on || clipPathSupported() ? 'visible' : 'hidden',
        },
      })}
      {React.cloneElement(onIcon, {
        className: classes.onIcon,
        style: {
          ...clipPath(
            on
              ? 'polygon(100% -100%, 100% 100%, -100% 100%)'
              : 'polygon(100% 100%, 100% 100%, 100% 100%)',
          ),
          visibility: on || clipPathSupported() ? 'visible' : 'hidden',
        },
      })}
    </div>
  )
}

export default withStyles(styles)(ToggleIcon)
