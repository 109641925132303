import { makeStyles, Theme } from '@material-ui/core/styles'

export const styles = (theme: Theme) => ({
  addButton: {
    position: 'fixed' as const,
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
  attendeeChip: { marginRight: 5, marginBottom: 5 },
  avatar: { color: '#fff', marginRight: theme.spacing(1) },
  expandSummary: { alignItems: 'center', display: 'flex', flexGrow: 1 },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  searchInput: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
})

export const useStyles = makeStyles(styles, { name: 'Main' })
