import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import styles from 'styles/components/PatchNotes'

class PatchNotes extends React.Component {
  render() {
    const { classes, onClose, version, updates, fixes, notes } = this.props
    return (
      <Dialog onClose={onClose} open>
        <DialogTitle>Patch Notes</DialogTitle>
        <DialogContent>
          <div className={classes.textBlock}>
            <Typography subtitle1>Version</Typography>
            {version}
          </div>
          <div className={classes.textBlock}>
            <Typography subtitle1>Notes</Typography>
            {notes}
          </div>
          <div className={classes.textBlock}>
            <Typography subtitle1>What's New?</Typography>
            <ul className={classes.textList}>
              {updates.map((update, index) => (
                <li key={index}>{update}</li>
              ))}
            </ul>
          </div>
          <div className={classes.textBlock}>
            <Typography subtitle1>Fixes</Typography>
            <ul className={classes.textList}>
              {fixes.map((fix, index) => (
                <li key={index}>{fix}</li>
              ))}
            </ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Sweet!
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

PatchNotes.defaultProps = {
  notes: 'Regular patch notes',
  updates: ['None'],
  fixes: ['None'],
}

export default withStyles(styles)(PatchNotes)
