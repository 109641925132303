import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Paper from '@material-ui/core/Paper'
import Icon from '@material-ui/core/Icon'

import styles from 'styles/components/SearchBar'

class SearchBar extends Component {
  state = {
    focus: false,
    active: false,
  }

  handleInput = event => {
    this.props.onChange(event.target.value)
  }

  handleCancel = () => {
    this.props.onChange('')
  }

  handleKeyPressed = event => {
    if (event.charCode === 27 || event.key === 'Escape') {
      event.preventDefault()
      this.handleCancel()
    }
  }

  render() {
    const { classes, disabled, value } = this.props

    return (
      <Paper className={classes.root}>
        <div className={classes.searchContainer}>
          <Input
            value={value}
            onChange={this.handleInput}
            onKeyUp={this.handleKeyPressed}
            fullWidth
            placeholder="Search"
            className={classes.input}
            disableUnderline
            disabled={disabled}
          />
        </div>
        <IconButton
          className={classes.iconButtonSearch}
          disabled={disabled}
          style={{
            opacity: !disabled ? 0.54 : 0.38,
            transform: value.trim().length ? 'scale(0, 0)' : 'scale(1, 1)',
          }}
        >
          <Icon
            className={classes.icon}
            style={{
              opacity: value.trim().length ? 0 : 1,
            }}
          >
            search
          </Icon>
        </IconButton>
        <IconButton
          className={classes.iconButtonClose}
          disabled={disabled}
          onClick={this.handleCancel}
          style={{
            opacity: !disabled ? 0.54 : 0.38,
            transform: value.trim().length ? 'scale(1, 1)' : 'scale(0, 0)',
          }}
        >
          <Icon
            className={classes.icon}
            style={{
              opacity: value.trim().length ? 1 : 0,
            }}
          >
            clear
          </Icon>
        </IconButton>
      </Paper>
    )
  }
}

export default withStyles(styles)(SearchBar)
