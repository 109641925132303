export const firstString = (...args) => {
  for (const arg of args) {
    if (arg && String(arg).trim().length) {
      return arg
    }
  }

  return 'N/A'
}

export default firstString
