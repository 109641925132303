import isValidEmail from 'helpers/validations/is-email'
import isNonEmptyString from 'helpers/validations/is-non-empty-string'
import isNumber from 'helpers/validations/is-number'

export const bookingSchema = [
  {
    key: 'title',
    type: 'text',
    validation: isNonEmptyString,
    error: 'Please enter a title',
  },
  {
    key: 'company',
    type: 'text',
  },
  {
    key: 'provider',
    type: 'select',
    options: {
      key: 'providers',
      name: 'name',
    },
    validation: Boolean,
    error: 'Please select a provider',
  },
  {
    key: 'date',
    type: 'date',
    get default() {
      return new Date()
    },
  },
  {
    key: 'comments',
    multiline: true,
    type: 'text',
  },
  {
    type: 'split',
    left: {
      key: 'cancelled',
      type: 'boolean',
      default: false,
      disabled: course => course && Boolean(Object.keys(course.attendees || {}).length),
    },
    right: {
      key: 'consultation',
      type: 'boolean',
      default: false,
      disabled: course => course && Boolean(Object.keys(course.attendees || {}).length),
    },
  },
]

export const providerSchema = [
  {
    default: '',
    error: 'Please enter a name',
    key: 'name',
    type: 'text',
    validation: isNonEmptyString,
  },
  {
    default: '#fff',
    key: 'color',
    type: 'color',
  },
]

export const registrationSchema = [
  {
    key: 'names',
    type: 'split',
    left: {
      default: '',
      error: 'Please enter your first name',
      key: 'firstName',
      type: 'text',
      validation: isNonEmptyString,
    },
    right: {
      default: '',
      error: 'Please enter your last name',
      key: 'lastName',
      type: 'text',
      validation: isNonEmptyString,
    },
  },
  {
    default: '',
    error: 'Please enter a valid email address',
    key: 'emailAddress',
    type: 'text',
    validation: text => !isNonEmptyString(text) || isValidEmail(text),
  },
  {
    default: '',
    error: 'Please enter your company name',
    key: 'company',
    type: 'text',
    validation: isNonEmptyString,
  },
  {
    error: 'Please sign in the box',
    key: 'signature',
    type: 'signature',
    validation: isNonEmptyString,
  },
]

export const pinUnlockSchema = [
  {
    default: '',
    error: 'Please enter a numeric PIN',
    key: 'pin',
    type: 'password',
    validation: pin => isNonEmptyString(pin) && isNumber(pin),
  },
]

export const userSchema = [
  {
    key: 'email',
    readOnly: true,
    type: 'email',
  },
  {
    default: '',
    key: 'displayName',
    type: 'text',
  },
  {
    default: '',
    key: 'pin',
    type: 'password',
  },
]

export const loginSchema = [
  {
    error: 'Please enter a valid email address',
    key: 'email',
    type: 'email',
    validation: isValidEmail,
  },
  {
    error: 'Please enter a password',
    key: 'password',
    type: 'password',
    validation: isNonEmptyString,
  },
]
