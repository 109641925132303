export const styles = theme => ({
  attendeeChip: { marginRight: 5, marginBottom: 5 },
  expandSummary: { alignItems: 'center', display: 'flex', flexGrow: 1 },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
})

export default styles
