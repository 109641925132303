import makeActionCreator from 'helpers/redux/makeActionCreator'

export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'

export const startLoading = makeActionCreator(START_LOADING)
export const stopLoading = makeActionCreator(STOP_LOADING)

export default {
  startLoading,
  stopLoading,
}
