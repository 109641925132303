import React, { Suspense } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'

import EditDialog from 'components/Dialogs/EditDialog/EditDialog'
import PatchNotes from 'components/Dialogs/PatchNotes'
import Header from 'components/Frame/Header'
import SideNav from 'components/Frame/SideNav'
import LoadingSplash from 'components/Layout/LoadingSplash'

import Registration from 'views/Registration'

import { AppState } from 'helpers/context'

import { auth, database } from 'services/firebase'
import { initMessaging } from 'services/notifications'
import styles from 'styles/components/Main'

import { routes } from 'constants/routes'
import { userSchema } from 'constants/schemas'

class Main extends React.Component {
  state = {
    courses: undefined,
    currentPatch: undefined,
    initialLoad: true,
    loading: true,
    mobileOpen: false,
    notification: undefined,
    pageTitle: 'Welcome',
    patches: undefined,
    providers: undefined,
    userEdit: false,
    userState: undefined,
  }

  componentDidMount() {
    const { user } = this.props
    this.databaseRef = database.ref()

    this.databaseRef.on('value', snapshot => {
      if (snapshot.val() !== null) {
        const snap = snapshot.val()
        this.setState(
          {
            courses: snap.courses,
            currentPatch: snap.patches[snap.patches.length - 1],
            patches: snap.patches,
            providers: snap.providers,
            userState: snap.users[user.uid],
          },
          () => {
            this.setState({ loading: false, initialLoad: false })
          },
        )
      } else {
        this.setState(
          {
            courses: undefined,
            currentPatch: undefined,
            patches: undefined,
            providers: undefined,
            userState: undefined,
          },
          () => {
            this.setState({ loading: false, initialLoad: false })
          },
        )
      }
    })

    initMessaging(message => {
      this.handleShowNotification(message)
    })
  }

  componentWillUnmount() {
    this.databaseRef.off()
  }

  handleShowNotification = ({ notification }) => {
    this.setState({ notification })
  }

  handleCloseNotification = () => {
    this.setState({ notification: null })
  }

  handleStartLoading = () => {
    this.setState({
      loading: true,
    })
  }

  handleStopLoading = () => {
    this.setState({
      loading: false,
    })
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }

  handleUpdateUser = ({ pin, ...user }) => {
    database.ref(`users/${this.props.user.uid}`).update({ pin })

    if (auth.currentUser) {
      auth.currentUser.updateProfile(user)
    }
    this.setState({ userEdit: false })
  }

  handleCancelEditUser = () => {
    this.setState({ userEdit: false })
  }

  handleEditUser = () => {
    this.setState({ userEdit: true })
  }

  handleClosePatchNotes = () => {
    database.ref(`users/${this.props.user.uid}`).update({ patch: process.env.REACT_APP_VERSION })
  }

  render() {
    const {
      courses,
      currentPatch,
      initialLoad,
      loading,
      mobileOpen,
      notification,
      providers,
      userEdit,
      userState,
    } = this.state
    const { classes, user } = this.props

    return (
      <Router>
        <AppState.Provider
          value={{
            startLoading: this.handleStartLoading,
            state: this.state,
            stopLoading: this.handleStopLoading,
          }}
        >
          <div className={classes.root}>
            <Suspense fallback={<LoadingSplash />}>
              {loading && <LoadingSplash />}
              {!initialLoad &&
                (userState.registrationMode ? (
                  <Registration courses={courses} userState={userState} user={user} />
                ) : (
                  <div className={classes.appFrame}>
                    <Snackbar
                      anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                      }}
                      open={Boolean(notification)}
                      autoHideDuration={5000}
                      onClose={this.handleCloseNotification}
                      message={
                        <span id="notification">
                          {notification && `${notification.title} - ${notification.body}`}
                        </span>
                      }
                      action={[
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={this.handleCloseNotification}
                        >
                          <Icon>close</Icon>
                        </IconButton>,
                      ]}
                    />
                    <Header
                      open={mobileOpen}
                      toggle={this.handleDrawerToggle}
                      user={user}
                      editUser={this.handleEditUser}
                    />
                    <SideNav open={mobileOpen} toggle={this.handleDrawerToggle} user={user} />
                    <main className={classes.content}>
                      {userEdit && (
                        <EditDialog
                          current={{
                            ...user,
                            pin: userState.pin || '',
                          }}
                          fields={userSchema}
                          title="Edit Account"
                          update={this.handleUpdateUser}
                          cancel={this.handleCancelEditUser}
                        />
                      )}
                      {userState.patch !== process.env.REACT_APP_VERSION && (
                        <PatchNotes {...currentPatch} onClose={this.handleClosePatchNotes} />
                      )}
                      <Switch>
                        {routes.map(({ exact, icon, path, redirect, Screen, ...props }) =>
                          redirect ? (
                            <Redirect key={path} exact={exact} path={path} to={redirect} />
                          ) : (
                            <Route
                              key={path}
                              path={path}
                              exact={exact}
                              render={() => (
                                <Screen
                                  user={user}
                                  courses={courses}
                                  providers={providers}
                                  {...props}
                                />
                              )}
                            />
                          ),
                        )}
                      </Switch>
                    </main>
                  </div>
                ))}
            </Suspense>
          </div>
        </AppState.Provider>
      </Router>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Main)
