export const styles = theme => ({
  textBlock: {
    paddingBottom: theme.spacing(1),
  },
  textList: {
    margin: 0,
  },
})

export default styles
