import { routes } from 'constants/routes'

export const getRouteTitle = location => {
  for (const route of routes) {
    if (location === route.path) {
      return route.title
    }
  }

  return 'Welcome'
}

export default getRouteTitle
