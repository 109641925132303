export const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 15,
  },
  paper: {
    padding: 16,
    // textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'center',
    margin: '0 25px',
  },
  rightGutterFixed: {
    position: 'fixed',
    pointerEvents: 'none',
  },
  rightGutterWrapped: {
    marginTop: 25,
  },
})

export default styles
