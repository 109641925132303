import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { database } from 'services/firebase'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'

import { routes } from 'constants/routes'
import { APP_TITLE } from 'constants/customization'
import styles from './styles'

class SideNav extends React.Component {
  navigateTo(link) {
    return () => {
      // Close the drawer on click
      this.props.toggle(false)

      // Only try to navigate if the target is not the current page
      if (this.props.location.pathname !== link.path) {
        this.props.history.push(link.path)
      }
    }
  }

  handleOpenPatchNotes = () => {
    database.ref(`users/${this.props.user.uid}`).update({
      patch: '',
    })
  }

  render() {
    const { classes, location } = this.props

    const drawerContent = (
      <div>
        <div className={classes.drawerHeader}>
          <div className={classes.drawerHeaderWrapper}>
            <Typography variant="h5" className={classes.drawerTitle}>
              {APP_TITLE}
            </Typography>
            <Typography variant="caption">
              <button onClick={this.handleOpenPatchNotes} className={classes.versionLink}>
                Version {process.env.REACT_APP_VERSION}
              </button>
            </Typography>
          </div>
        </div>
        <Divider />
        <List>
          {routes
            .map(link => ({ ...link, active: link.path === location.pathname }))
            .map(
              link =>
                !link.redirect && (
                  <ListItem
                    className={clsx(link.active && classes.activeListItem)}
                    key={link.path}
                    onClick={this.navigateTo(link)}
                    button={!link.active}
                  >
                    <ListItemIcon className={clsx(link.active && classes.activeListIcon)}>
                      <Icon>{link.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: link.active ? classes.activeListText : classes.listText }}
                      primary={link.title}
                    />
                  </ListItem>
                ),
            )}
        </List>
      </div>
    )

    return (
      <div>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="left"
            open={this.props.open}
            classes={{ paper: classes.drawerPaper }}
            onClose={this.props.toggle}
            ModalProps={{ keepMounted: true }}
          >
            {drawerContent}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer variant="permanent" open classes={{ paper: classes.drawerPaper }}>
            {drawerContent}
          </Drawer>
        </Hidden>
      </div>
    )
  }
}

SideNav.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(SideNav))
