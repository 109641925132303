import React from 'react'
import PropTypes from 'prop-types'
import firebase from 'services/firebase'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import ParticleBackground from 'components/Layout/ParticleBackground'
import EditDialog from 'components/Dialogs/EditDialog/EditDialog'

import { loginSchema } from 'constants/schemas'
import styles from './styles'

class Login extends React.Component {
  state = {
    processing: false,
    authFailed: false,
  }

  handleSubmit = ({ email, password }) => {
    this.setState({ processing: true, authFailed: false })
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(() => {
        this.setState({ processing: false, authFailed: true })
      })
  }

  render() {
    const { classes, title, loading } = this.props
    const { processing, authFailed } = this.state

    return (
      <ParticleBackground>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <EditDialog
          card
          errorMessage={authFailed && 'Authentication failed'}
          fields={loginSchema}
          loading={processing || loading}
          noTitle
          submitLabel="Login"
          update={this.handleSubmit}
        />
      </ParticleBackground>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

export default withStyles(styles)(Login)
