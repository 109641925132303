export const styles = theme => ({
  title: {
    color: theme.palette.primary.contrastText,
    marginBottom: 12,
    fontSize: 50,
    fontFamily: "'Monoton', cursive",
  },
  spinner: { color: theme.palette.primary.contrastText },
})

export default styles
