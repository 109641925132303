import { lazy } from 'react'

import compareAsc from 'date-fns/compareAsc'
import compareDesc from 'date-fns/compareDesc'

// Screens
import CourseList from 'views/CourseList'
import ProviderList from 'views/ProviderList'

// Lazy loaded screens
const Dashboard = lazy(() => import('views/Dashboard'))
const DataGenerator = lazy(() => import('views/DataGenerator'))

// Navigation Settings
export const routes = [
  {
    title: 'Home',
    path: '/',
    exact: true,
    redirect: '/courses',
  },
  {
    title: 'Dashboard',
    path: '/dashboard',
    Screen: Dashboard,
    icon: 'show_chart',
  },
  {
    title: 'Courses',
    path: '/courses',
    icon: 'people',
    Screen: CourseList,
    filter: course => !course.archive,
    sort: (a, b) => compareAsc(new Date(a[1].date), new Date(b[1].date)),
  },
  {
    title: 'Providers',
    path: '/providers',
    Screen: ProviderList,
    icon: 'business',
  },
  {
    title: 'Course Archive',
    path: '/archive',
    Screen: CourseList,
    icon: 'archive',
    filter: course => course.archive,
    sort: (a, b) => compareDesc(new Date(a[1].date), new Date(b[1].date)),
    readOnly: true,
  },
  {
    title: 'Data Generator',
    path: '/generator',
    Screen: DataGenerator,
    icon: 'grain',
  },
]
