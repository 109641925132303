import { red } from '@material-ui/core/colors'
import { createStyles, Theme } from '@material-ui/core/styles'

export const styles = (theme: Theme) =>
  createStyles({
    actions: {
      justifyContent: 'flex-end',
    },
    card: {
      maxWidth: 600,
      minWidth: 350,
    },
    dialog: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: 600,
      },
    },
    errorMessage: {
      color: red[500],
      fontWeight: 'bold',
      marginTop: 16,
      textAlign: 'center',
    },
    hiddenSubmit: {
      display: 'none',
    },
    loadingWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(3),
    },
  })

export default styles
