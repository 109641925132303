import React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { ThemeProvider } from 'styled-components'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import 'helpers/console/init-dev-console'

import * as serviceWorker from './serviceWorker'
import { theme } from 'config/theme'

import store from 'store'
import { firebase } from 'services/firebase'
import App from './App'
import 'assets/css/styles.css?v=1.3.0'

import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles'

const rootEl = document.getElementById('root')

const AppRoot = () => (
  <div>
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        firebase={firebase}
        config={{ userProfile: 'users' }}
        dispatch={store.dispatch}
      >
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <StylesProvider injectFirst>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <App />
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  </div>
)

if (rootEl.hasChildNodes()) {
  hydrate(<AppRoot />, rootEl)
} else {
  render(<AppRoot />, rootEl)
}

serviceWorker.register()
